<template>
    <div class="container">
        <content-title :nav="nav"></content-title>
        <div class="contentList">
            <h4 class="sec-title">商家入住意向列表</h4>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="商家名称">
                    <el-input type="text" clearable v-model="name" />
                </el-form-item>
                <el-form-item label="商家手机号">
                    <el-input type="text" clearable v-model="mobile" />
                </el-form-item>
                <!-- <el-form-item label="类型">
                    <el-input type="text" clearable v-model="type" />
                </el-form-item> -->
                <el-form-item>
                    <el-button @click="search()" type="primary">查询</el-button>
                </el-form-item>
            </el-form>
            <div class="paging">
                <el-table :header-cell-style="{ background: '#EAEAEA', color: '#434343' }" :data="list"
                    tooltip-effect="dark" style="width: 100%; margin-top: 20px" highlight-current-row
                    v-loading="is_loading">
                    <el-table-column prop="id" align="center" label="id"></el-table-column>
                    <el-table-column prop="mechanism_name" align="center" label="商家名称"></el-table-column>
                    <el-table-column prop="principal_mobile" align="center" label="联系方式"></el-table-column>
                    <el-table-column prop="category_name" align="center" label="类型"></el-table-column>
                    <el-table-column prop="city" align="center" label="地址">
                        <template slot-scope="scope">
                            <el-tooltip :content="scope.row.province + scope.row.city + scope.row.address"
                                placement="bottom" effect="light">
                                <el-button type="text">地址</el-button>

                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" align="center" label="申请时间"></el-table-column>
                    <el-table-column prop="create_time" align="center" label="状态">
                        <template slot-scope="scope">
                            <el-button type="text" @click="search(scope.row.id)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页-->
                <paging-fy @currentPageChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                </paging-fy>
            </div>
        </div>
        <el-dialog title="详情" :visible.sync="dialogVisible" width="600px">
            <div class="details">
                <div>商品名称：{{ user.mechanism_name }}</div>
                <div>联系方式：{{ user.principal_mobile }}</div>
                <div>类型：{{ user.category_name }}</div>
                <div>商品名称：{{ user.mechanism_name }}</div>
                <div>商品名称：{{ user.mechanism_name }}</div>
                <div>地址：{{ user.province + user.city + user.address }}</div>
                <div>申请时间：{{ user.create_time }}</div>
                <div>营业执照：<img style="width: 100px;height: 100px;" :src="user.shop_img" alt=""></div>
                <div>商户门面：<img style="width: 100px;height: 100px;" :src="user.business_license_img" alt=""></div>
                <div>其他图片：<img v-for="(item) in user.else_img" style="width: 100px;height: 100px;margin-right: 10px;"
                        :key="item" :src="item" alt=""></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            nav: {
                firstNav: "运营管理中心",
                secondNav: "商户意向列表",
            },
            list: [],
            is_loading: true,
            currentPage: 1,
            total: 0,
            name: '',
            mobile: '',
            type: '',
            user: {},
            dialogVisible: false,
        };
    },
    mounted() {
        this.request();
    },
    methods: {
        request() {
            let url = 'user/mechanismIntention/queryManagerListPage';
            this.$get(url).then(res => {
                this.list = res.data.data.rows;
                this.total = res.data.data.total
                this.is_loading = false
            })
        },
        search(id) {
            let url = 'user/mechanismIntention/queryManagerListPage';
            let data = {
            }
            if (this.type) {
                data.category_name = this.type
            }
            if (this.mobile) {
                data.principal_mobile = this.mobile
            }
            if (this.name) {
                data.mechanism_name = this.name
            }
            if (id) {
                data.id = id;
            }
            this.$get(url, data).then(res => {
                if (res.data.message == '成功') {
                    if (id) {
                        res.data.data.rows[0].else_img.split(',')
                        this.user = res.data.data.rows[0]
                        this.dialogVisible = true
                    } else {
                        this.list = res.data.data.rows;
                    }
                }

            })
        },
        handleCurrentChange(v) {
            this.currentPage = v;
            this.search()
        }
    }


};
</script>
<style lang="less">
.details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 14px;
        color: #333333;
        width: 49%;
    }
}

.PERInformation-from>*:not(:last-child) {
    margin-right: 1rem;
}
</style>
